<template>
  <Layout>
    <PageHeader :title="title1" :items="items"></PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="email" class="row">
              <b-form-group
                id="input-group-3"
                label-for="input-3"
                class="col-6"
              >
                <label style="font-weight: 500">Redeem Point</label>
                <b-form-input
                  id="input-3"
                  placeholder="Enter Redeem Point"
                  v-model="redeemForm.points"
                >
                </b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label-for="input-2"
                class="col-6"
              >
                <label style="font-weight: 500">Note</label>
                <b-form-textarea
                  id="message"
                  v-model="redeemForm.notes"
                  placeholder="Add note"
                  rows="4"
                >
                </b-form-textarea>
              </b-form-group>
              <div class="col-12">
                <b-button variant="primary" class="mr-2" @click="redeemPoint">
                  Redeem Points
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import PointsContent from "../../../mixins/ModuleJs/points";

export default {
  components: {
    Layout,
    PageHeader,
  },
  mixins: [MixinRequest, PointsContent],
  data() {
    return {
      submitted: false,
      title1: "Redeem Points",
      items: [
        {
          text: "Back",
          href: "/points",
        },
        {
          text: "Data",
        },
      ],
    };
  },
};
</script>
